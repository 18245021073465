<!-- table_area -->
<template>
  <table width="100%" class="table table-hover">
    <colgroup>
      <col v-if="isCheckbox" style="width: 50px" />
      <col v-if="useNo" style="width: 70px" />
      <col
        v-for="(item, index) in fields"
        :key="`col_${index}`"
        :style="{ width: item.width ? item.width : '' }"
      />
      <col v-if="isEdit || isDelete" style="width: 80px" />
    </colgroup>
    <thead>
      <tr>
        <th v-if="isCheckbox" scope="col">
          <div class="custom-control custom-checkbox" title="전체선택">
            <input
              class="custom-control-input selectall"
              id="selectall_1"
              v-model="checkAll"
              type="checkbox"
              @click="check"
            />
            <label for="selectall_1" class="custom-control-label"></label>
            <span>{{ checkText ? checkText : "" }}</span>
          </div>
        </th>
        <th v-if="useNo">번호</th>
        <th
          class="table_title"
          v-for="(item, index) in fields"
          :key="`th_${index}`"
        >
          <span
            v-if="item.useSort"
            @click.prevent.stop="
              sortClick($event, item, fields, index, clickedNum)
            "
          >
            {{ item.label }}
            <!-- XXX sort 개선필요-->
            <i>
              <font-awesome-icon
                v-if="
                  clickedNum == 0 ||
                  item.clickedNum == 0 ||
                  clickedNum != item.clickedNum
                "
                :icon="['fas', 'sort']"
                >-</font-awesome-icon
              >
              <font-awesome-icon
                v-if="clickedNum == 1 && item.clickedNum == 1"
                :icon="['fas', 'sort-down']"
              />
              <font-awesome-icon
                v-if="clickedNum == 2 && item.clickedNum == 2"
                :icon="['fas', 'sort-up']"
              />
            </i>
          </span>
          <span v-else> {{ item.label }}</span>
        </th>
        <th v-if="isEdit">{{ editText }}</th>

        <th v-if="isDelete">삭제</th>
      </tr>
    </thead>
    <tbody>
      <tr v-if="loading && items.length == 0">
        <td colspan="100" class="text-center align-middle">
          {{ loadingMessage }}
        </td>
      </tr>
      <tr v-else-if="!loading && items.length == 0">
        <td colspan="100" class="text-center align-middle">
          {{ emptyMessage }}
        </td>
      </tr>
      <tr v-else v-for="(item, index) in items" :key="index">
        <td v-if="isCheckbox" class="text-center align-middle">
          <div class="custom-control custom-checkbox">
            <template v-if="selectType == 'checkbox'">
              <input
                class="custom-control-input"
                type="checkbox"
                :value="item"
                :id="item.id"
                v-model="checked"
              />
              <label :for="item.id" class="custom-control-label"></label>
            </template>
            <template v-else>
              <input
                type="radio"
                :value="item.id"
                :id="item.id"
                v-model="checked"
              />
            </template>
          </div>
        </td>
        <td v-if="useNo" class="text-center align-middle">
          {{ $n(pageSize * (currentPage - 1) + index + 1) }}
        </td>
        <td
          v-for="(field, index) in fields"
          :key="`td_${index}`"
          class="text-center align-middle"
        >
          <template v-if="hasValue(item, field)">
            <component
              v-if="field.tag"
              :is="'tag-' + field.tag"
              :item="item"
              :field="field"
              :id="item.id"
              @click="handleClick"
              :useClick="field.useClick"
              :useSort="field.useSort"
              :oneText="field.oneText"
              :data="itemValue(item, field)"
            ></component>

            <!-- /// -->
            <template v-else-if="field.useSlot">
              <slot :name="field.key" :item="item" />
            </template>
            <!-- /// -->

            <template v-else>
              <a
                style="display: inline-block"
                href="#"
                v-if="field.useClick"
                @click.prevent.stop="handleClick($event, item, field)"
              >
                <div v-if="field.oneText" class="oneText">
                  {{ itemValue(item, field) ? itemValue(item, field) : "-" }}
                </div>
                <div v-else>
                  {{ itemValue(item, field) ? itemValue(item, field) : "-" }}
                </div>
              </a>
              <a
                href="#"
                v-else-if="field.downloadClick"
                @click.prevent.stop="downloadClick($event, item, field)"
                download
              >
                {{ itemValue(item, field) ? itemValue(item, field) : "-" }}
              </a>
              <div v-else-if="field.oneText" class="oneText">
                {{ itemValue(item, field) ? itemValue(item, field) : "-" }}
              </div>
              <template v-else>
                {{ itemValue(item, field) ? itemValue(item, field) : "-" }}
              </template>
            </template>
          </template>
          <template v-else> - </template>
        </td>
        <template v-if="isEdit">
          <td class="text-center align-middle" style="min-width: 100px">
            <button
              type="button"
              @click="editClick($event, item)"
              class="btn_tbl_icon"
            >
              <i><font-awesome-icon :icon="['fas', 'edit']" /></i>
            </button>
            <button
              type="button"
              @click="deleteClick($event, item)"
              class="btn_tbl_icon"
            >
              <i><font-awesome-icon :icon="['fas', 'trash-alt']" /></i>
            </button>
          </td>
        </template>
        <template v-if="isDelete">
          <td class="text-center align-middle">
            <button
              type="button"
              @click="deleteClick($event, item)"
              class="btn_tbl_icon"
            >
              <i><font-awesome-icon :icon="['fas', 'trash-alt']" /></i>
            </button>
          </td>
        </template>
      </tr>
    </tbody>
  </table>
</template>

<script>
import img from "./Img";
import { mapGetters } from "vuex";

export default {
  name: "BaseTable",
  components: {
    "tag-img": img,
  },
  props: {
    useNo: {
      type: Boolean,
      default: false,
      description: "Table loading",
    },
    totalItems: {
      type: Number,
      default: 0,
      description: "",
    },
    currentPage: {
      type: Number,
      default: 0,
      description: "",
    },
    pageSize: {
      type: Number,
      default: 0,
      description: "",
    },
    loadingMessage: {
      type: String,
      default: "조회중..",
      description: "",
    },
    emptyMessage: {
      type: String,
      default: "검색 결과가 없습니다.",
      description: "",
    },
    fields: {
      type: Array,
      default: () => [],
      description: "Table Fields",
    },
    loading: {
      type: Boolean,
      default: true,
      description: "Table loading",
    },
    items: {
      type: Array,
      default: () => [],
      description: "Table Items",
    },
    isEdit: {
      type: Boolean,
      default: true,
      description: "Show edit buttons",
    },
    isDelete: {
      type: Boolean,
      default: false,
      description: "Show only delete button",
    },
    isCheckbox: {
      type: Boolean,
      default: false,
      description: "Show checkbox",
    },
    modelValue: {
      type: String,
      default: "",
    },
    trueValue: {
      type: Boolean,
      default: true,
    },
    falseValue: {
      type: Boolean,
      default: false,
    },
    defaultChecked: {
      type: [Array, Number, String],
      default: () => [],
      description: "Table checked",
    },
    editText: {
      type: String,
      default: "수정",
    },
    checkText: {
      type: String,
      default: "",
    },
    selectType: {
      type: String,
      default: "checkbox",
    },
    clickedNum: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      checkAll: false,
      checked: [],
      radioaa: "checked",
    };
  },
  created() {
    if (this.selectType == "checkbox") {
      this.checked = this.defaultChecked;
    } else if (this.selectType == "radio") {
      this.checked = this.defaultChecked;
    } else {
      this.checked = this.defaultChecked.toString();
    }
  },
  mounted() {
    /**
     * mounted
     */
  },
  watch: {
    checked(value) {
      if (!this.checked.length) {
        this.checkAll = false;
      } else if (this.items.length !== this.checked.length) {
        this.checkAll = false;
      } else {
        this.checkAll = true;
      }
      this.$emit("onChecked", value);
    },
  },
  computed: {
    ...mapGetters(["getUser"]),
  },
  unmounted() {
    /**
     * destroyed
     */
  },
  methods: {
    check() {
      this.checked = [];
      if (!this.checkAll) {
        for (const i in this.items) {
          this.checked.push(this.items[i]);
        }
      }
    },
    handleClick(event, item, field) {
      this.$emit("onTableClick", {
        event: event,
        item: item,
        field: field,
      });
    },
    sortClick(event, item, field, index, clickedNum) {
      this.$emit("sort", {
        event: event,
        item: item,
        field: field,
        index: index,
        clickedNum: clickedNum,
      });
    },
    editClick(event, item) {
      this.$emit("edit", {
        event: event,
        item: item,
      });
    },
    deleteClick(event, item) {
      this.$emit("delete", {
        event: event,
        item: item,
      });
    },
    hasValue(item, field) {
      /**
       * XXX
       * 로직 개선 필요.
       */
      if (1 < field.key.split(".").length) {
        if (
          Object.prototype.hasOwnProperty.call(item, field.key.split(".")[0])
        ) {
          if (
            Object.prototype.hasOwnProperty.call(
              item[field.key.split(".")[0]],
              field.key.split(".")[1]
            )
          ) {
            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      } else {
        if (Object.prototype.hasOwnProperty.call(item, field.key)) {
          return true;
        } else {
          return false;
        }
      }
    },
    itemValue(item, field) {
      /**
       * XXX
       * 로직 개선 필요.
       */
      let value = "";
      if (1 < field.key.split(".").length) {
        value = item[field.key.split(".")[0]][field.key.split(".")[1]];
      } else {
        value = item[field.key];
      }
      if (field.formatter) {
        if (field.formatter.name == "date") {
          try {
            value = this.$d(new Date(value).getTime(), field.formatter.type);
          } catch (e) {
            console.log(e);
            value = "";
          }
        } else if (field.formatter.name == "number") {
          value = this.$n(value);
        } else if (field.formatter.name == "percent") {
          if (value) {
            console.log(value);
            value = value.toFixed(2);
          }
        }
      }
      return value;
    },
  },
};
</script>
