<template>
  <div class="content">
    <div class="tbl_wrap">
      <div class="tbl_head float_area">
        <div class="float-left">
          <div class="tbl_result">
            <h5>
              클레임 관리<span class="tit_desc"
                >검색결과 (총 {{ state.totalItems }}건)</span
              >
            </h5>
          </div>
        </div>
        <div class="float-right align-items-center">
          <div class="tbl_btnset">
            <form
              class="search-group"
              @submit.prevent="
                getItems({
                  clmStDate: '20220101',
                  clmEdDate: '20230102',
                  clmField:
                    'IDX|ORDER_ID|MALL_ID|CLAME_STATUS_GUBUN|ORDER_DATE|CLAME_CONTENT|PRODUCT_NAME',
                  page: page,
                  size: state.pageSize,
                })
              "
            ></form>
            <router-link :to="{ name: 'ReadClaimList' }"></router-link>
          </div>
        </div>
      </div>
      <base-table
        :useNo="true"
        :fields="state.fields"
        :items="state.items"
        :is-edit="false"
        :loading="state.contentLoading"
        :totalItems="state.totalItems"
        :pageSize="state.pageSize"
        :currentPage="state.currentPage"
        @onChecked="onChecked"
        @onTableClick="onTableClick"
      >
      </base-table>
      <nav class="mt-20px">
        <base-pagination
          v-if="state.totalPages > 1"
          :total="state.totalItems"
          :loading="state.contentLoading"
          :currentPage="state.currentPage"
          :pageCount="state.totalPages"
          :perPage="state.pageSize"
          @change="
            (page) =>
              getItems({
                clmStDate: '20220101',
                clmEdDate: '20230102',
                clmField:
                  'IDX|ORDER_ID|MALL_ID|CLAME_STATUS_GUBUN|ORDER_DATE|CLAME_CONTENT|PRODUCT_NAME',
                page: page,
                size: state.pageSize,
              })
          "
        />
      </nav>
    </div>
  </div>
</template>

<script setup>
import { onMounted, reactive } from "vue";
import { useRouter } from "vue-router";
import ClaimService from "@/services/claim.service";
import BasePagination from "@/components/core/BasePagination.vue";
import BaseTable from "@/components/core/BaseTable/BaseTable.vue";

// # hooks
const router = useRouter();

// # state
const state = reactive({
  items: [],
  checkedItems: [],
  contentLoading: true,
  totalItems: 0,
  totalPages: 0,
  currentPage: 1,
  pageSize: 15,
  fields: [
    // table thead fields
    {
      key: "IDX",
      label: "주문번호(사방넷)",
      useClick: true,
      width: "10%",
    },
    {
      key: "ORDER_ID",
      label: "주문번호(쇼핑몰)",
      useClick: true,
      width: "15 %",
    },
    {
      key: "MALL_ID",
      label: "쇼핑몰명",
      useClick: true,
      width: "10%",
    },
    {
      key: "CLAME_STATUS_GUBUN",
      label: "구분",
      useClick: true,
      width: "7%",
    },
    {
      key: "ORDER_DATE",
      label: "주문날짜",
      useClick: true,
      width: "12%",
    },
    {
      key: "PRODUCT_NAME",
      label: "상품명",
      useClick: true,
      width: "21%",
    },
    {
      key: "CLAME_CONTENT",
      label: "내용",
      useClick: true,
      width: "21%",
    },
  ],
});

// # method
// 다건 조회
const getItems = async (
  params = {
    clmStDate: "20220101",
    clmEdDate: "20230101",
    clmField:
      "IDX|ORDER_ID|MALL_ID|CLAME_STATUS_GUBUN|ORDER_DATE|CLAME_CONTENT|PRODUCT_NAME",
    page: 1,
    size: 15,
  }
) => {
  const res = await ClaimService.findAll(params);
  const result = division(res.data.SABANG_ORDER_LIST.DATA, params.size);
  state.items = result[params.page - 1];
  state.totalItems = Number(res.data.SABANG_ORDER_LIST.HEADER.TOTAL_COUNT);
  state.currentPage = params.page;
  state.totalPages = Math.ceil(state.totalItems / params.size);
  state.contentLoading = false;
  state.pageSize = params.size;
};

// 체크
const onChecked = (items) => {
  state.checkedItems = items.map((item) => item.id);
};

// 테이블 클릭 이벤트 받기
const onTableClick = (params) => {
  const id = params.item.id;
  // router.push({
  //   name: "ReadClaimItem",
  //   params: { id: id },
  // });
};

// 배열 num개 기준으로 나누기
const division = (arr, num) => {
  const length = arr.length;
  const divide =
    Math.floor(length / num) + (Math.floor(length % num) > 0 ? 1 : 0);
  const newArray = [];

  for (let i = 0; i <= divide; i++) {
    // 배열 0부터 n개씩 잘라 새 배열에 넣기
    newArray.push(arr.splice(0, num));
  }

  return newArray;
};

// # lifecycle
onMounted(() => {
  getItems();
});
</script>

<style lang="scss" scoped>
.posts {
  display: flex;
  flex-direction: column;
  gap: 10px;
  .post {
    display: flex;
    gap: 5px;
  }
  a {
    color: blue;
  }
}
.search-group {
  display: flex;
  gap: 10px;
}
.filter-wrap {
  display: flex;
  justify-content: flex-end;
  gap: 20px;
}
.btn-group {
  display: flex;
  gap: 10px;
  * {
    display: inline-block;
    padding: 5px 10px;
    background-color: #ddda;
    border-radius: 5px;
    color: #666;
    &:hover {
      background-color: #ddd;
    }
  }
}
.tbl_btnset {
  font-size: unset;
  display: flex;
  .search-group {
    button {
      flex-basis: 50px;
    }
  }
}
</style>
